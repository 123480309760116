<template>
  <a-form-model ref="form" :model="form" :rules="rules">
    <a-form-model-item label="腾讯云邮件密匙ID" prop="apiKey" >
      <a-input v-model="form.apiKey" placeholder="请输入腾讯云邮件SecretId" />
    </a-form-model-item>
    <a-form-model-item label="腾讯云密钥SECRET" prop="apiSecret" >
      <a-input v-model="form.apiSecret" placeholder="请输入腾讯云邮件SecretKey" type="password" />
    </a-form-model-item>
    <a-form-model-item  prop="apiRegion" label="腾讯云区域ID" >

      <a-input v-model="form.apiRegion" placeholder="请输入腾讯云邮件RegionId"/>
    </a-form-model-item>

    <a-form-model-item :wrapper-col="{ span: 18, offset: 10 }">
      <a-button type="primary" :loading="submitLoading" @click="submitForm">
        保存
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import {getEmailChannel, updateEmailChanne } from '@/api/email/channel'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        apiKey: null,
        apiSecret: null,
        apiRegion: null,
      },
      rules: {
        apiKey: [
          { required: true, message: '腾讯云邮件SecretId不能为空' }
        ],
        apiSecret: [
          { required: true, message: '腾讯云邮件SecretKey不能为空' }
        ],
        apiRegion: [
          { required: true, message: '腾讯云邮件RegionId不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    getEmailChannel("TENCENT").then(respons =>{
      this.form = respons.data
    })
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEmailChanne(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>