<template>
  <a-form-model ref="form" :model="form" :rules="rules">
    <a-form-model-item label="发送邮箱账号" prop="apiKey" >
      <a-input v-model="form.apiKey" placeholder="请输入账号" />
    </a-form-model-item>
    <a-form-model-item label="发送邮箱密码" prop="apiSecret" >
      <a-input v-model="form.apiSecret" placeholder="请输入密码" type="password" />
    </a-form-model-item>
    <a-form-model-item  prop="apiRegion" >
      <span slot="label">
        发送邮箱smtp
        <a-tooltip>
          <template slot="title">
            可以省略，省略后默认为邮箱账号smtp.邮箱后缀,例如：邮箱账号为：zbtech@163.com，smtp为smtp.163.com
          </template>
          <a-icon type="question-circle-o" />
        </a-tooltip>
      </span>
      <a-input v-model="form.apiRegion" placeholder="请输入邮箱smtp"/>
    </a-form-model-item>

    <a-form-model-item :wrapper-col="{ span: 18, offset: 10 }">
      <a-button type="primary" :loading="submitLoading" @click="submitForm">
        保存
      </a-button>
    </a-form-model-item>
  </a-form-model>
</template>
<script>
import {getEmailChannel, updateEmailChanne } from '@/api/email/channel'

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      submitLoading: false,
      // 表单参数
      form: {
        id: null,
        apiKey: null,
        apiSecret: null,
        apiRegion: null,
      },
      rules: {
        apiKey: [
          { required: true, message: '邮箱账号不能为空' }
        ],
        apiSecret: [
          { required: true, message: '邮箱密码不能为空' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
    getEmailChannel("LOCAL").then(respons =>{
      this.form = respons.data
    })
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {

    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateEmailChanne(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>